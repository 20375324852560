<template>
    <div>
      <div class="progres-box" v-if="showStatusBar">
        <div class="progres-box-inside">
          <div data-v-515de9e4="" id="progres" class="progresbar-5 "></div>
        </div>
      </div>
        <md-app>
            
            <md-app-toolbar class="md-primary" v-if="!mobile">
                <router-link :to="{name: 'dashboard.premium'}">
                    <img src="https://rehab-connect.s3.amazonaws.com/assets/logo_home.svg" alt="Rehab.com Logo" class="rehab-logo">
                </router-link>
            </md-app-toolbar>

            <md-app-content v-if="funnelData">
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item form">
                   
                        <div class="headers">
                            <h1 v-if="!showStatusBar">Primary payment method</h1>
                            <h2>You are in control. You can change your budget anytime.</h2>
                        </div>
                        <md-dialog-content class="options">
                            <new-payment-method-form
                                    v-show="!funnelData.paymentCreated"
                                    style="text-align: left;"
                                    :redirectOnSubmit="false"
                                    @paymentMethodCreated="finishFunnel()"
                                    @goBack="funnelData.currentStep=$event"
                                    @showStatusBar="showStatusBar = true"
                                    @hideStatusBar="showStatusBar = false"
                                    ref="newPaymentForm"
                            ></new-payment-method-form>

                        </md-dialog-content>
<!--                        <md-dialog-actions v-if="funnelData.paymentCreated">-->
<!--                            <md-button class="md-raised" @click="funnelData.currentStep=4">Back</md-button>-->
<!--                            <md-button-->
<!--                                    class="md-primary md-raised"-->
<!--                                    @click="funnelData.currentStep=6"-->
<!--                            >Next</md-button>-->

<!--                        </md-dialog-actions>-->



                    </div>

                </div>
            </md-app-content>
        </md-app>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import NewPaymentMethodForm from './NewPaymentMethodForm'
    export default {
        name: "Checkout",
        components: {
            NewPaymentMethodForm
        },
        data() {
            return {
                funnelData: null,
                mobile: false,
                showStatusBar: false,
            }
        },

        computed: {
            ...mapGetters([
                'goPremiumFunnelInfo',
                'calculateBudget',
                'campaigns',
                'facilities',
                'products',
                'trackingNumbers',
                'trackingUrls',
                'subscriptions',
            ]),
        },
        created() { 
             if( this.windowWidth <= 760 ) {
                this.mobile = true
            }  
        },
        mounted() {
            this.funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
            this.funnelData.currentStep = this.$route.name;
            this.updateGoPremiumFunnelInfo(this.funnelData);
        },

        filters: {
            formatPrice: (value) => {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            callClickRange: (value) => {
                const low = Math.ceil(value / 2);
                const high = parseInt(value * 1.5);
                return `${high}`
            }
        },

        beforeRouteLeave (to, from, next) {
          if(this.showStatusBar){
            if(to.name === 'dashboard.premium'){
              next();
            }else {
              next(false);
            }
          }else {
            next()
          }
        },

        methods: {
            ...mapActions([
                'updateGoPremiumFunnelInfo',
                'fetchDefaultPaymentMethod',
                'makeInitialPayment',
                "updateCampaign",
                'createSubscriptions',
                'fetchCampaigns',
                'deleteCreditCard'
            ]),


            async finishFunnel(){
                let defaultCreditCard = null;
                try{
                    defaultCreditCard = await this.fetchDefaultPaymentMethod();

                    // GET ALL CAMPAIGNS FOR FACILITY
                    let campaigns = this.campaigns.filter(campaign => parseInt(campaign.attributes.facilityId) === parseInt(this.goPremiumFunnelInfo.funnelFacility));

                    // console.log('FIRST CAMPAIGNS', JSON.parse(JSON.stringify(campaigns)));

                    const callProduct = this.products.find(product => product.attributes.slug === 'premium-calls');
                    const clickProduct = this.products.find(product => product.attributes.slug === 'premium-clicks');

                    // FILTER CAMPAIGN BY GOAL IF NEEDED.
                    if(this.funnelData.goal === 'calls'){
                        campaigns = campaigns.filter(campaign => {
                            return parseInt(campaign.attributes.productId) === parseInt(callProduct.id)
                        })
                    }else if(this.funnelData.goal === 'clicks'){
                        campaigns = campaigns.filter(campaign => parseInt(campaign.attributes.productId) === parseInt(clickProduct.id))
                    }

                    // console.log('campaigns', campaigns);
                    // console.log('FILTERED CAMPAIGNS', JSON.parse(JSON.stringify(campaigns)))

                    const budgetData = this.calculateBudget({numInteractions: this.funnelData.budget});

                    // CREATE SUBSCRIPTION FOR FUNNEL FACILITY
                    //console.log('THIS.SUBSCRIPTIONS', this.subscriptions);
                    const facilitySubscription = this.subscriptions.find(subscription => parseInt(subscription.facilityId) === parseInt(this.goPremiumFunnelInfo.funnelFacility));

                    // console.log('FACILITY SUBSCRIPTION', facilitySubscription);

                    if(!facilitySubscription){
                        await this.createSubscriptions([this.goPremiumFunnelInfo.funnelFacility])
                        // console.log('THIS.SUBSCRIPTIONS AGAIN', JSON.parse(JSON.stringify(this.subscriptions)))
                    }

                    if(!this.funnelData.isSubscriptionOnly){ // DON'T START CAMPAIGNS IF WE'RE ONLY ADDING SUBSCRIPTIONS
                        for(const campaign of campaigns){
                            // console.log("CAMPAIGN IN LOOP", JSON.parse(JSON.stringify(campaign)));
                            const facilityBudget = budgetData.facilityBudgets.find(budget => budget.facilityId === campaign.attributes.facilityId);
                            // console.log("FACILITY BUDGET IN LOOP", JSON.parse(JSON.stringify(facilityBudget)));
                            let budget = 0;

                            // SET RECEIVING URL
                            let receivingUrl = null
                            const receivingUrlObj = this.trackingUrls.find(trackingUrls => parseInt(trackingUrls.facilityId) === parseInt(campaign.attributes.facilityId))
                            if(receivingUrlObj) receivingUrl = receivingUrlObj.url


                            // SET RECEIVING NUMBER
                            let receivingNumber = null;
                            const receivingNumberObj = this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(campaign.attributes.facilityId))
                            if(receivingNumberObj) receivingNumber = receivingNumberObj.receivingNumber

                            // SET/GET TRACKING NUMBER
                            let trackingNumber = null
                            const trackingNumberObj = this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(campaign.attributes.facilityId))
                            if(trackingNumberObj) trackingNumber = trackingNumberObj.number
                            var data = null

                            // SET BUDGET FOR CALLS & CLICKS COMBO GOAL
                            if(this.funnelData.goal === 'callsAndClicks'){
                                if(parseInt(campaign.attributes.productId) === parseInt(callProduct.id)){
                                    budget = facilityBudget.callBudget*0.3
                                  //   console.log('SHOULD HAVE SET BUDGET FOR CALLS: ', budget * 0.3)
                                } else{
                                    budget = facilityBudget.clickBudget*0.7
                                    // console.log('SHOULD HAVE SET BUDGET FOR CLICKS: ', budget * 0.7 )
                                }
                            }else if(this.funnelData.goal === 'calls' && parseInt(campaign.attributes.productId) === parseInt(callProduct.id)){
                                budget = facilityBudget.callBudget
                               //  console.log('SHOULD HAVE SET BUDGET FOR CALLS: ', budget)
                            }else {
                                budget = facilityBudget.clickBudget
                               //  console.log('SHOULD HAVE SET BUDGET FOR CLICKS: ', budget )
                            }

                            if((campaign.attributes.productId === parseInt(callProduct.id) && receivingNumber !== null) || (campaign.attributes.productId === parseInt(clickProduct.id) && receivingUrl !== null)){
                                //Based on data in VueX create the tracking URL/Number records in our Node DB
                                if(campaign.attributes.productId == parseInt(clickProduct.id)){
                                    data = receivingUrl
                                }else{
                                    if(!trackingNumber){
                                        await this.$store.dispatch('ctmBuyNumber', {number: receivingNumber, facilityId: campaign.attributes.facilityId, campaignId: campaign.id})
                                    }

                                    data = this.trackingNumbers.find(trackingNumber => parseInt(trackingNumber.facilityId) === parseInt(campaign.attributes.facilityId)).number
                                }
                                const result = await this.updateCampaign({
                                    campaign: campaign,
                                    status: true,
                                    budget,
                                    data
                                })
                                 console.log('UPDATE CAMPAIGN RESULT', JSON.parse(JSON.stringify(result)));
                            } else {
                                 console.log('THERE WAS NO RECEIVING URL/NUMBER FOR THIS CAMPAIGN', JSON.parse(JSON.stringify(campaign)))
                            }

                        }
                    }

                    //SET USER STATUS TO 1
                    this.$store.commit('updateUserStatus', 1);

                    this.interactionFilter = 'Premium'

                    if(!this.funnelData.isSubscriptionOnly){
                        this.$store.dispatch('setGoPremiumCompleteFlag')
                        this.$root.$emit('gopremiumthanks')
                    }

                    await this.fetchCampaigns();


                    this.$router.push({ name: 'dashboard.premium', query: {'premium-campaigns': true} })
                }catch (e) {
                    this.$store.dispatch('setGoPremiumCompleteFlag')
                    console.log('DELETING DEFAULT CREDIT CARD', defaultCreditCard);
                    if(defaultCreditCard){

                        this.deleteCreditCard(defaultCreditCard.id)
                    }
                    this.showFunnelSnackbar = true
                    this.snackMsg = "There was an error: " + e.toString();
                    console.error('ERROR IN FINISH FUNNEL FUNCTION:', e.response)
                    console.error('ERROR IN FINISH FUNNEL FUNCTION:', e.toString(), e)
                    if(e.response && e.response.data && e.response.data.error){
                      this.$refs.newPaymentForm.stopLoadingError(`${e.response.data.error} If this error persists, please try a different card or <a href="mailto:support@rehab.com" target="_blank">contact support</a>.`)
                    }else {
                      this.$refs.newPaymentForm.stopLoadingError('Payment error. Please try again. If this error persists, please try a different card or <a href="mailto:support@rehab.com" target="_blank">contact support</a>.')
                    }

                    throw e;
                }

            }

        }

    }
</script>

<style lang="scss" scoped>
    .page-container, .md-app, .md-app-container, .md-app-scroller, .md-layout-column, .md-flex, .md-theme-default, .md-scrollbar, .md-content, .md-drawer, .md-theme-default, .md-tabs-content {
        background-color: #ffffff !important;
    }

    .choice-tabs {
        .md-tab {
            background-color: #fff;
        }
    }

    .md-primary{
        border: 1px solid #eee
    }
    
    .md-toolbar {
        background-color: #FAFAFA !important;
    }

    .rehab-logo {
        height: 32px;
        margin-left: 55px;
    }

    .headers {
        text-align: center;

        h1{
            font-size: 36px;
            font-weight: 300;
            display: block;
            text-align: center;
            padding-top: 0;
            margin-top: 0;
        }

        h2 {
            font-weight: 300;
            font-size: 24px;
            line-height: 26px;
        }

    }
    
    .next-btn {
        .md-button {
            height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            box-shadow: none;
            color: #3671FF;
            border: 1px solid #3671FF;
            text-transform: none;
            margin-left: 0;
            margin-right: 0;
            margin-top: 30px;
            float: right;
        }

        .md-primary {
            color: #fff;
            border: none;
            background-color: #3671FF !important;
        }
    }

    .md-layout-item.form{
          max-width: 700px;
      }

    @media only screen and (max-width: 760px),
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .headers {
            text-align: center;

            h1{
                font-size: 28px;
                font-weight: 300;
                margin-bottom: 10px;
                letter-spacing: -1px;
                margin-top: 6px;
            }

            h2 {
                font-size: 15px;
                margin-top: 0px;
                line-height: 20px;
                font-weight: 300;
            }

        }
        .details-container{
            .name, .zip{
                margin-bottom: 0px!important;
            }
        }

        .md-field{
            margin-bottom: 0px!important;
        }
        .md-app-content{
            padding-left: 20px;
            padding-top: 20px;
        }
        .md-dialog-content{
            padding: 0px;
        }
         .rehab-logo{
            margin-left: 5px!important;
        }
        .options{
            height: 99vh;
        }

        .progres-box {
            position: fixed;
            margin: 0 auto;
            text-align: center;
            height: 8px;
            background-color: #e7e7e7;
            border-radius: 8px;
            z-index: 10;
            overflow: hidden;
            top: 0;
            width: 100%;
            //margin-left: -223px;
            //margin-right: auto;
            //left: 50%;
            .progres-box-inside {
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                .progresbar-5[data-v-515de9e4] {
                    position: absolute;
                    height: 8px;
                    width: 90%;
                    background-color: #0078d3 !important;
                    z-index: 11;
                }
            }
        }


    }

</style>